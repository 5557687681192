import React, { useEffect, useState, useRef } from "react";
import FormItem from "./FormItem";
import { FaTimes } from 'react-icons/fa';
import { 
    loadCaptchaEnginge, 
    LoadCanvasTemplateNoReload, 
    validateCaptcha 
}  from 'react-simple-captcha';

const Accordeon = (props) => {
    const [data, setData] = useState({});
    const [formSended, setFormSended] = useState(false);
    const [formOpened, setFormOpened] = useState(false);
    const [btnActive, setBtnActive] = useState(false);
    const [error, setError] = useState("");
    const [userInput, setUserInput] = useState('');

    const captchaRef = useRef(null);

    const Domain = "https://xanalytics.ru";

    useEffect(() => {
        const canvas = captchaRef.current;
        if (canvas) {
            loadCaptchaEnginge(6);
        }
    }, [formOpened])

    const fieldHandler = (name, value) => {
        setValue(name, value);
    };

    const setValue = (name, value) => {
        // console.log(name, value);
        // alert(`${name}: ${value}`)
        data[name] = value;
        value ?? delete data[name];
        if (value === "") delete data[name];
        setData({ ...data });
        // console.log(Object.keys(data));
        if (
            Object.keys(data).includes("fio") &&
            Object.keys(data).includes("company") &&
            Object.keys(data).includes("phone") &&
            Object.keys(data).includes("email") && 
            Object.keys(data).includes("consent_personal_data") &&
            data["consent_personal_data"]
        ) {
            setBtnActive(true);
        } else {
            setBtnActive(false);
        }
    };

    const handleCheckboxChange = (name) => (event) => {
        setValue(name, event.target.checked);
    };

    const clearForm = () => {
        Object.entries(data).forEach(([key]) => {
            data[key] = '';
        });
        setData({ ...data });
        setBtnActive(false);
    }

    const sendForm = () => {
        if (!validateCaptcha(userInput)) {
            setError('Пожалуйста, подтвердите, что вы не робот.');
            return;
        }
        const formData = new FormData();
        for (const [key, item] of Object.entries(data)) {
        formData.append(key, item);
        // console.log('====================================');
        // console.log(key, item);
        // console.log('====================================');
        }

        fetch(`${Domain}/api/${props.form}/`, {
            method: "POST",
            headers: {},
            body: formData,
        })
        .then((response) => {
            if (!response.ok) {
            setError("Неверно заполнены поля");
            throw new Error("Ошибка. Неверно заполнены поля");
            } else {
                setFormSended(true);
            }
            return response.json();
        })
        .catch((error) => {
            console.log("error", error.message);
        });
    };

    return (
        <div className="accordion">
            <div className="accordion-header" onClick={() => setFormOpened(!formOpened)}>
                <span>{props.head}</span>
                <span className="toggle">{formOpened ? '-' : '+'}</span>
            </div>
            {!!formOpened  &&
                <div className="accordion-content">
                    {!formSended ? (
                        <div className="accordion-form">
                            <span className="form-error">{error}</span>
                            <p>
                                {props.form_head}
                            </p>
                            <form action="" method="POST">
                                <div className="form-container">
                                    <FormItem
                                        type={"text"}
                                        name={"fio"}
                                        classes={"_full"}
                                        value={data.fio}
                                        fieldHandler={fieldHandler}
                                        placeholder={"ФИО"}
                                        label={"Фамилия Имя Отчество"}
                                    />
                                    <FormItem
                                        type={"text"}
                                        name={"company"}
                                        classes={"_full"}
                                        value={data.company}
                                        fieldHandler={fieldHandler}
                                        label={"Компания"}
                                    />
                                    <FormItem
                                        type={"phone"}
                                        name={"phone"}
                                        value={data.phone}
                                        fieldHandler={fieldHandler}
                                        placeholder={"+7"}
                                        label={"Телефон"}
                                        setValue={setValue}
                                    />
                                    <FormItem
                                        type={"email"}
                                        name={"email"}
                                        value={data.email}
                                        fieldHandler={fieldHandler}
                                        placeholder={""}
                                        label={"Электронная почта"}
                                    />
                                    <FormItem
                                        type={"text"}
                                        name={"goal"}
                                        classes={"_full"}
                                        value={data.goal}
                                        fieldHandler={fieldHandler}
                                        label={props.goal}
                                    />
                                </div>
                                <label>
                                    <input type="checkbox" checked={data["consent_personal_data"]} onChange={handleCheckboxChange("consent_personal_data")} />
                                    Я даю согласие на обработку персональных данных
                                </label>
                                <label>
                                    <input type="checkbox" checked={data["consent_info"]} onChange={handleCheckboxChange("consent_info")} />
                                    Я даю согласие на получение информационных материалов
                                </label>
                                {/* <input type="submit" id="save_button" className="d-none" value="Send" /> */}
                                <div className="captcha-container">
                                    <LoadCanvasTemplateNoReload ref={captchaRef} />
                                    <input
                                        type="text"
                                        value={userInput}
                                        onChange={(e) => setUserInput(e.target.value)}
                                        placeholder="Введите капчу"
                                        required
                                    />
                                </div>
                                <div className="button-group">
                                    <button
                                        onClick={clearForm}
                                        className="clear_button"
                                        aria-label="Очистить"
                                    >
                                        <FaTimes /> {/* Иконка крестика */}
                                        <span>Очистить</span>
                                    </button>
                                    <label
                                        onClick={(e) => sendForm()}
                                        htmlFor="save_button"
                                        className={`${btnActive ? "" : "disabled"} save_button`}
                                    >
                                        Отправить
                                    </label>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className="form-success">
                            <p className="form-sended_title">Заявка отправлена </p>
                            <p className="form-sended_subtitle">
                                Мы рассмотрим вашу заявку и&nbsp;обязательно с&nbsp;вами
                                свяжемся
                            </p>
                        </div>
                    )}
                    
                </div>
            }
        </div>
    )
}

export default Accordeon;