import React, { useState } from "react";

function FormItem(props) {
  const [num, setNum] = useState();

  return (
    <div className={`form__item ${props.classes ? props.classes : ""}`}>
      {/* <span className="error-message">{(props.fieldDirty && props.error) && props.error}</span> */}
      <input
        type={props.type}
        name={props.name}
        value={num ? num : props.value}
        // onChange={(e) => props.fieldHandler(props.name, e.target.value)}
        onChange={
          props.mask
            ? (e) => {
                props.mask(e, setNum);
                props.fieldHandler(props.name, num);
              }
            : (e) => props.fieldHandler(props.name, e.target.value)
        }
        // onChange={props.handler ? e => props.handler(e, props.field, props.setValue, props.setError) : props.mask ? e => props.mask(e, props.setValue, props.setError) : e => props.fieldHandler(e)}

        className={`form-field`}
        placeholder={props.placeholder}
        required={true}
      />
      <label className="form-label" htmlFor={props.name}>
        {props.label}
      </label>
    </div>
  );
}

export default FormItem;
