import React from "react";
import Accordeon from "./Accordeon";
import { useNavigate } from 'react-router-dom';

const SyncSaveHik = (props) => {
  const navigate = useNavigate();
  
  const closeModal = (e) => {
      e.preventDefault();
      props.openModal(false);
      document.body.classList.remove("hidden");
      navigate("/")
  };

  return (
    <>
      <div className={"syncSaveHik active"} onClick={closeModal}>
        <div className={`syncSaveHik__content`} onClick={(e) => e.stopPropagation()}>
            <div className="syncSaveHik-header">
                <p className="syncSaveHik-title">SyncSaveHik</p>
                <img
                    className="syncSaveHik-close"
                    src="/img/close.svg"
                    alt="закрыть"
                    onClick={closeModal}
                />
            </div>
            <div className="links">
              <a href="/products/syncsavehik/doc/SyncSaveHik - About - RU_1.0.0.pdf" download>О программе</a>
              <a href="/products/syncsavehik/doc/SyncSaveHik - Operator's Manual - RU_1.0.0.pdf" download>Руководство оператора</a>
            </div>
            <Accordeon 
              form={'cost'}
              head={'Запрос стоимости'}
              goal={'Цель приобретения продукта'}
              form_head={'Отправьте ваш запрос через форму ниже, и наши специалисты свяжутся с вами в ближайшее время'}
            />
            <Accordeon 
              form={'download'}
              head={'Скачать ПО'}
              goal={'Цель скачивания программного обеспечения'}
              form_head={'Отправьте ваш запрос через форму ниже, и наши специалисты свяжутся с вами в ближайшее время. В случве положительного решения будет предоставлена ссылка для скачивания.'}
            />
        </div>
      </div>
    </>
  );
};

export default SyncSaveHik;