import React from "react";
import Home from "./Home";
import { 
  BrowserRouter as Router,
  Routes, 
  Route
} from 'react-router-dom';


const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home sync={false}/>} />
        <Route path="/syncsavehik" element={<Home sync={true}/>} />
      </Routes>
    </Router>
  );
};

export default App;
